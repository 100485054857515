import {Fill, Stroke, Circle, RegularShape, Style, Text as TextStyle, Icon} from 'ol/style';
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import Polygon from "ol/geom/Polygon";
import CircleStyle from 'ol/style/Circle';
import proj4 from 'proj4';
import {mapProjEPSG} from './projection_setup';

import $ from "jquery";


import {toContext} from 'ol/render';

import {DEVICE_PIXEL_RATIO} from 'ol/has'

//Most Code from Where Where Wedgie left as an example only


var _hoverFeatures;

var _opacityMap;

const styleCache = new Map();

var _canvas = document.createElement('canvas');

//Vector tiles don't seem to suport the normal style function used by the select ("hover") tool?
export function setHoverFeaturesForVTStyle(hoverFeaturesSet){
    _hoverFeatures = hoverFeaturesSet;
}

export var mapStyles = {
    customerStyle: customerStyle,
    
    hubBoundaryStyle: new Style({
        stroke: new Stroke({
            color: [197,0,255,1.0],
            width: 3
        }),
    }),

    haulageStyle: new Style({
        stroke: new Stroke({
            color: [197,0,255,1.0],
            width: 3
        }),
    }),

    plantationStyleByOwner: plantationStyleByOwner,
    plantationStyleByOwnerLegend:plantationStyleByOwnerLegend,
    plantationStyleByRotation: plantationStyleByRotation,
    plantationStyleByRotationLegend:plantationStyleByRotationLegend,
    plantationStyleByAge:plantationStyleByAge,
    plantationStyleByAgeLegend:plantationStyleByAgeLegend,
    haulageBytotalVolume: haulageBytotalVolume,
    plantationStyleByAgeRotation: plantationStyleByAgeRotation,
    plantationStyleByAgeRotationLegend:plantationStyleByAgeRotationLegend,
    otherSoftwoodStyle:otherSoftwoodStyle,
    MaiStylebByClass:MaiStylebByClass,
    MaiStylebByClassLegend:MaiStylebByClassLegend,
    plantationHoverStyle: plantationHoverStyle,

    roadStyle: roadStyle,

    positionStyle : new Style({
        image: new Circle({
        radius: 6,
        fill: new Fill({
          color: '#3399CC'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2
        })
      })
    }),

   
    surveyCellIDLabelStyle : surveyCellIDLabelStyle,
    surveyCellVertexLabelStyle : surveyCellVertexLabelStyle,


    gpsStyle : gpsStyle
};

export var legendImages = {
    customer: customerStylelegendImageSrc(),
    // hubBoundary: getLegendImage
}


function customerStylelegendImageSrc(){
    return './static/icon_sawmill.png';
}

function otherSoftwoodStyle(feature, resolution)
{
    let styles = [];
        styles.push(new Style({
            fill: new Fill({
                color: [197,0,255,1.0],
            }),
            stroke: new Stroke({
                width:0.5,
                color: [197,0,255,1.0],
            }),
        
        }));

        try{
            let ids = new Set();
            _hoverFeatures.forEach(f=>{
                ids.add(f)
            })
            if(ids.has(feature)){
                styles.push(new Style({
                    stroke: new Stroke({
                        color: [128, 128, 255, 1.0],
                        width: 3
                    }),
                    zIndex:99
                }));
            }
           
        }
        catch(e){}


        return styles;
}

function customerStyle(feature, resolution){
    let styles = [];
    styles.push(new Style({
        image: new Icon({
            scale: 0.5,
            src: customerStylelegendImageSrc()
        }),
        zIndex:999
    }))
    let fProp = feature.getProperties();

    styles.push(new Style({
        text: new TextStyle({
            text: fProp.Customer,
            font:'400 18px "Arial"',
            fill: new Fill({
                color: [255,255,255,1.0],
            }),
            // rotation : rotation,
            textBaseline: 'bottom',
            offsetY:30 * (fProp.Customer=="Borg Panels - OTC"?-0.5:1),
            stroke: new Stroke({color: [0,0,0,1.0], width: 2}),
        }),
        
        zIndex:999
    }));

    return styles;
}

const plantationStyleByOwnerstyleDict = {};


function plantationStyleByOwner(feature, resolution, filter){
    let styles = [];

    let fProp = feature.getProperties();

    let opacity = 1;

    var mapOpacity =  _opacityMap.get(filter);

    if(mapOpacity != null)
    {
        opacity = mapOpacity;
    }

    let styleKey = filter.replace(" ", "") + "Key";

 

        if(filter == null || filter == fProp.owner){
            let colour = [255, 0, 255, 1.0];
            let strokeColour = [255, 0, 255, 1.0];

            switch (fProp.owner) {
                case "FCNSW":
                    colour = [211,255,190,opacity];
                    strokeColour = [88,255,4];
                    break;
                case "FCNSW JV":
                    colour = [255,255,190,opacity];
                    strokeColour = [255,255,16];
                    break;

                case "Hume":
                    colour = [190,210,255,opacity];
                    strokeColour = [16,120,255];
                    break;

                case "Private":
                    colour = [255,190,190,opacity];
                    strokeColour = [255,16,16];
                    break;
                case "PPP":
                    colour = [255,190,232,opacity];
                    strokeColour = [255,16,200];
                    break;
            
                default:
                    break;
            }

         
            if(plantationStyleByOwnerstyleDict[styleKey] != null)
            {
                if(plantationStyleByOwnerstyleDict[styleKey].fill_.color_[3] = opacity)
                {
                    styles.push(plantationStyleByOwnerstyleDict[styleKey]);
                }
                else
                {
                    let currentStyle = new Style({
                        fill: new Fill({
                            color: colour,
                        }),
                        stroke: new Stroke({
                            width:0.5,
                            color: strokeColour,
                        }),
        
                    });

                    plantationStyleByOwnerstyleDict[styleKey] = currentStyle;
                    styles.push(plantationStyleByOwnerstyleDict[styleKey]);

        
                }
                
            }
            else
            {
                let currentStyle = new Style({
                    fill: new Fill({
                        color: colour,
                    }),
                    stroke: new Stroke({
                        width:0.5,
                        color: strokeColour,
                    }),
    
                });
            plantationStyleByOwnerstyleDict[styleKey] = currentStyle;
            styles.push(currentStyle);
         }

        }
        else
        {
            return null;
        }

    try{
        let ids = new Set();
        _hoverFeatures.forEach(f=>{
            ids.add(f.getProperties().gid)
        })
        if(ids.has(fProp.gid)){
            styles.push(new Style({
                stroke: new Stroke({
                    color: [128, 128, 255, 1.0],
                    width: 3
                }),
                zIndex:99
            }));
        }
       
    }
    catch(e){}
   

    return styles;
}


function plantationStyleByOwnerLegend(name){

    if(name != null){
        let colour = "rgb(255, 0, 255, 1.0)";
        let strokeColour = "rgb(255, 0, 255, 1.0)";

        switch (name) {
            case "FCNSW":
                colour = "rgb(211,255,190)";
                strokeColour = "rgb(88,255,4)";
                break;
            case "FCNSW JV":
                colour = "rgb(211,255,190)";
                strokeColour = "rgb(255,255,16)";
                break;

            case "Hume":
                colour = "rgb(190,210,255)";
                strokeColour = "rgb(16,120,255)";
                break;

            case "Private":
                colour = "rgb(255,190,190)";
                strokeColour = "rgb(255,16,16)";
                break;
            case "PPP":
                colour = "rgb(255,190,232)";
                strokeColour = "rgb(255,16,200)";
                break;
        
            default:
                break;
        }

        return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="-3 -6 20 30"><path d="M4 2.81783C6.22857 1.14009 10.1429 0.920389 11.9286 1.02025C11.9286 1.02025 13 1.31985 12.3571 1.91904C11.7143 2.51823 11.2632 3.03858 10.8571 3.41701C9.82857 4.37572 10.4286 5.21458 10.8571 5.51418L15.3571 7.31175C15.5714 7.51148 16 8.39029 16 10.3077C14.2857 15.1012 4 16 2.5 16C1.3 15.5206 1 13.9028 1 13.3036C1 12.7045 4.21428 9.70851 4 9.40891C3.78571 9.10932 1.98571 7.25183 1.64286 7.01215C1.3 6.77248 1.35714 5.91364 1.42857 5.51418C1.35714 5.31445 1.77143 4.49556 4 2.81783Z" fill="${colour}" stroke="${strokeColour}"/></svg>`;
    }
}

function haulageBytotalVolume(feature, resolution, filter1, filter2)
{
    let styles = [];

    let fProp = feature.getProperties();

        let colour = [255, 0, 255, 0];
        let strokeWidth = 1;
        let totalVolume;


        if(filter1 != null)
        {
            switch (filter1)
            {
                case "Volume Summary 2021-2030":
                   totalVolume = fProp.TOT_VOL_20
                   break;
                case "Volume Summary 2031-2040":
                    totalVolume = fProp.TOT_VOL_21
                    break;
                case "Volume Summary 2041-2050":
                    totalVolume = fProp.TOT_VOL_22
                    break;
            }
        }

        if(filter2 == '0 - 50,000')
        {
            if(totalVolume > 0 && totalVolume <= 50000)
            {
                colour = [2,255,197,1.0]; //mediumspringgreen
                strokeWidth = 0.75;
            }
        }
        else if(filter2 == '50,001 - 100,000')
        {
            if(totalVolume > 50000 && totalVolume <= 100000)
            {
                colour = [66,242,144,1.0]; //green
                strokeWidth = 1.5;
            }
        }
        else if(filter2 == '100,001 - 500,000')
        {
            if(totalVolume > 100000 && totalVolume <= 500000)
            {
                colour = [89,237,124, 1.0]; // yellow with orange tint 
                strokeWidth = 2;
            }
        }
        else if(filter2 == '500,001 - 1,000,000')
        {
            if(totalVolume > 500000 && totalVolume <= 1000000)
            {
                colour = [162,221,63, 1.0]; // orange 
                strokeWidth = 2.5;
            }

        } else if(filter2 == '1,000,001 - 2,000,000')
        {
            if(totalVolume > 1000000 && totalVolume <= 2000000)
            {
                colour = [161,217,76, 1.0]; // orange with red tint 
                strokeWidth = 3;
            }

        } else if(filter2 == '2,000,001 - 3,000,000')
        {
            if(totalVolume > 2000000 && totalVolume <= 3000000)
            {
                
                colour = [211,211,23, 1.0]; // red
                strokeWidth = 3.5;
            }

            
        }
        else if(filter2 == '3,000,001 - 4,000,000')
        {
            if(totalVolume > 3000000 && totalVolume <= 4000000)
            {
                colour = [220,179,18, 1.0];
                strokeWidth = 4;
            }
        }
        else if(filter2 == '4,000,001 - 5,000,000')
        {
            if(totalVolume > 4000000 && totalVolume <= 5000000)
            {
                colour = [234,103,10, 1.0];
                strokeWidth = 4.5;
            }
        }

        else if(filter2 == '5,000,001 - 6,000,000')
        {
            if( totalVolume > 5000000 && totalVolume <= 6000000)
            {
                colour = [240, 46, 46, 1.0];
                strokeWidth = 5;
            }
        }

        else if(filter2 == '6,000,001 - 6,500,000')
        {
            if(totalVolume > 6000000 && totalVolume <= 6500000)
            {
                colour = [240, 46, 46, 1.0];
                strokeWidth = 5.5;
            }
        }

        else if(filter2 == '6,000,001 - 7,000,000')
        {
            if( totalVolume > 6000000 && totalVolume <= 7000000)
            {
                colour = [240, 46, 46, 1.0];
                strokeWidth = 6;
            }
        }

        else if(filter2 == '7,000,001 - 8,000,000')
        {
            if(totalVolume > 7000000 && totalVolume <= 8000000)
            {
                colour = [240, 46, 46, 1.0];
                strokeWidth = 6.5;
            }
        }

        else if(filter2 == '8,000,001 - 9,000,000')
        {
            if(totalVolume > 8000000 && totalVolume <= 9000000)
            {
                colour = [240, 46, 46, 1.0];
                strokeWidth = 7;
            }
        }

        else if(filter2 == '9,000,001 - 10,000,000')
        {
            if( totalVolume > 9000000 && totalVolume <= 10000000 )
            {
                colour = [240, 46, 46, 1.0];
                strokeWidth = 7.5;
            }
        }
     

        styles.push(new Style({
            stroke: new Stroke({
                color: colour,
                width: strokeWidth
              }),
              zIndex:1
        }));

    return styles;
}


function plantationStyleByRotation(feature, resolution, filter){
    let styles = [];

    let fProp = feature.getProperties();

    let opacity = 1;

    var mapOpacity =  _opacityMap.get(filter);

    if(mapOpacity != null)
    {
        opacity = mapOpacity;
    }

    if(filter == null || filter == fProp.rotation){
        let colour = [128, 128, 255, opacity];
        let strokeColour = [255, 0, 255, opacity];

        switch (fProp.rotation) {
            case "0R":
                colour = [190,210,255,opacity];
                strokeColour = [16,120,255];
                break;
            case "1R":
                colour = [190,255,232,opacity];
                strokeColour = [138,244,216];
                break;

            case "2R":
                colour = [211,255,190,opacity];
                strokeColour = [88,255,4];
                break;

            case "3R":
                colour = [255,255,190,opacity];
                strokeColour = [255,255,16];
                break;

            case "4R":
                colour = [255,190,190,opacity];
                strokeColour = [255,16,16];
                break;

            default:
                break;
        }

        styles.push(new Style({
            fill: new Fill({
                color: colour,
            }),
            stroke: new Stroke({
                width:0.5,
                color: strokeColour,
            }),
        }));
    }

    try{
        let ids = new Set();
        _hoverFeatures.forEach(f=>{
            ids.add(f.getProperties().gid)
        })
        if(ids.has(fProp.gid)){
            styles.push(new Style({
                stroke: new Stroke({
                    color: [128, 128, 255, 1.0],
                    width: 3
                }),
                zIndex:99
            }));
        }
       
    }
    catch(e){}
   
    return styles;
}

function plantationStyleByAgeRotation(feature, resolution, rotation,age){
    let styles = [];

    let opacity = 1;

    let mapKey = age+","+rotation;

    if(rotation == "Other" || age == "Other")
    {
        mapKey = "Other";
    }

    var mapOpacity =  _opacityMap.get(mapKey);

    if(mapOpacity != null)
    {
        opacity = mapOpacity;
    }


    let fProp = feature.getProperties();
    let colour = [128, 128, 255, 1.0];
    let strokeColour = [255, 0, 255, 1.0];

    if(age == fProp.plant_year && rotation == fProp.rotation){


//"Other","30+","21-30","11-20","1-10"

        switch (fProp.rotation) {
            case "0R":
                colour = [255,127,127,opacity];
                break;
            case "1R":
                colour = [255,211,127,opacity];
                break;

            case "2R":
                colour = [255,255,115,opacity];
                break;

            case "3R":
                colour = [255,255,190,opacity];
                break;

            case "4R":
                colour = [115,178,255,opacity];
                break;
            default:
                break;
        }

        switch (fProp.plant_year) {
            case "2011-2021":
                strokeColour = [255,70,66];
                break;
            case "2001-2010":
                strokeColour = [157,240,94];
                break;

            case "1991-2001":
                strokeColour = [108,132,214];
                break;

            case "1990 & older":
                strokeColour = [255,41,182];
                break;

            default:
                strokeColour = [255,16,16];
                break;
        }

        styles.push(new Style({
            fill: new Fill({
                color: colour,
            }),
            stroke: new Stroke({
                width:0.5,
                color: strokeColour,
            }),
        }));
    }
    else if (age == "Other" && rotation == "Other")
    {

        if(fProp.agegroup != "2011-2021" && fProp.agegroup != "2001-2010" && fProp.agegroup != "1991-2001" && fProp.agegroup != "1990 & older" )
        {
            strokeColour = [255,16,16];
            colour =  [128, 128, 255, opacity];

            styles.push(new Style({
                fill: new Fill({
                    color: colour,
                }),
                stroke: new Stroke({
                    width:0.5,
                    color: strokeColour,
                }),
            }));

        }

else if (fProp.agegroup == "2011-2021" && fProp.rotation == "0R" || age == "2011-2021" && fProp.rotation == "4R")
{
    strokeColour = [255,16,16];
            colour =  [128, 128, 255, opacity];

            styles.push(new Style({
                fill: new Fill({
                    color: colour,
                }),
                stroke: new Stroke({
                    width:0.5,
                    color: strokeColour,
                }),
            }));
}


else if (fProp.agegroup == "1991-2001" && fProp.rotation == "3R" || age == "1991-2001" && fProp.rotation == "4R")
{
    strokeColour = [255,16,16];
    colour =  [128, 128, 255, opacity];

    styles.push(new Style({
        fill: new Fill({
            color: colour,
        }),
        stroke: new Stroke({
            width:0.5,
            color: strokeColour,
        }),
    }));
}

else if (fProp.agegroup == "1990 & older" && fProp.rotation == "0R" || age == "1990 & older" && fProp.rotation == "4R")
{
    strokeColour = [255,16,16];
    colour =  [128, 128, 255, opacity];

    styles.push(new Style({
        fill: new Fill({
            color: colour,
        }),
        stroke: new Stroke({
            width:0.5,
            color: strokeColour,
        }),
    }));
}

       
    }

    try{
        let ids = new Set();
        _hoverFeatures.forEach(f=>{
            ids.add(f.getProperties().gid)
        })
        if(ids.has(fProp.gid)){
            styles.push(new Style({
                stroke: new Stroke({
                    color: [128, 128, 255, 1.0],
                    width: 3
                }),
                zIndex:99
            }));
        }
       
    }
    catch(e){}
   
    return styles;
}

function plantationStyleByAgeRotationLegend(feature, resolution, rotation,age){
    let styles = [];

    let fProp = feature.getProperties();
    let colour = [128, 128, 255, 1.0];
    let strokeColour = [255, 0, 255, 1.0];

    if(age == fProp.agegroup && rotation == fProp.rotation){


//"Other","30+","21-30","11-20","1-10"
switch (fProp.rotation) {
    case "0R":
        colour = "rgb(255,127,127)";
        break;
    case "1R":
        colour = "rgb(255,211,127)";
        break;

    case "2R":
        colour = "rgb(255,255,115)";
        break;

    case "3R":
        colour = "rgb(255,255,190)";
        break;

    case "4R":
        colour = "rgb(115,178,255)";
        break;
    default:
        break;
}

switch (fProp.agegroup) {
    case "1-10":
        strokeColour = "rgb(255,70,66)";
        break;
    case "21-30":
        strokeColour = "rgb(157,240,94)";
        break;

    case "21-30":
        strokeColour = "rgb(108,132,214)";
        break;

    case "30+":
        strokeColour = "rgb(255,41,182)";
        break;

    default:
        strokeColour = "rgb(255,16,16)";
        break;
}
}
else if (age == "other" && rotation == "other")
{
switch (fProp.agegroup) {
    case "1-10":
        strokeColour = "rgb(255,70,66)";
        break;
    case "21-30":
        strokeColour = "rgb(157,240,94)";
        break;

    case "21-30":
        strokeColour = "rgb(108,132,214)";
        break;

    case "30+":
        strokeColour = "rgb(255,41,182)";
        break;

    default:
        strokeColour = "rgb(255,16,16)";
        colour = "rgb(255,115,223)"
        break;
}

    }
   
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="-3 -6 20 30"><path d="M4 2.81783C6.22857 1.14009 10.1429 0.920389 11.9286 1.02025C11.9286 1.02025 13 1.31985 12.3571 1.91904C11.7143 2.51823 11.2632 3.03858 10.8571 3.41701C9.82857 4.37572 10.4286 5.21458 10.8571 5.51418L15.3571 7.31175C15.5714 7.51148 16 8.39029 16 10.3077C14.2857 15.1012 4 16 2.5 16C1.3 15.5206 1 13.9028 1 13.3036C1 12.7045 4.21428 9.70851 4 9.40891C3.78571 9.10932 1.98571 7.25183 1.64286 7.01215C1.3 6.77248 1.35714 5.91364 1.42857 5.51418C1.35714 5.31445 1.77143 4.49556 4 2.81783Z" fill="${colour}" stroke="${strokeColour}"/></svg>`;
}
function plantationStyleByAgeLegend(name){
    
    if(name != null){

        let colour = "RGB(128, 128, 255, 1.0)";
        let strokeColour = "RGB(255, 0, 255, 1.0)";

        switch (name) {
            case "Clearfell in Progress":
                colour = "RGB(0,112,255)";
                strokeColour = "RGB(83,214,69)";
                break;
            case "30+":
                colour = "RGB(38,115,0)";
                strokeColour = "RGB(83,214,69)";
                break;

            case "21-30":
                colour = "RGB(76,230,0)";
                strokeColour = "RGB(83,214,69)";
                break;

            case "11-20":
                colour = "RGB(163,255,115)";
                strokeColour = "RGB(83,214,69)";
                break;

            case "1-10":
                colour = "RGB(233,255,190)";
                strokeColour = "RGB(83,214,69)";
                break;
                case "Planting in Progress":
                    colour = "RGB(255,235,175)";
                    strokeColour = "RGB(255,189,56)";
                    break;
                    case "Unplanted":
                        colour = "RGB(255,190,190)";
                        strokeColour = "RGB(255,87,87)";
                        break;
                        case "Regrowth":
                            colour = "RGB(255,190,232)";
                            strokeColour = "RGB(255,87,215)";
                            break;
        
            default:
                break;
        }

        return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="-3 -6 20 30"><path d="M4 2.81783C6.22857 1.14009 10.1429 0.920389 11.9286 1.02025C11.9286 1.02025 13 1.31985 12.3571 1.91904C11.7143 2.51823 11.2632 3.03858 10.8571 3.41701C9.82857 4.37572 10.4286 5.21458 10.8571 5.51418L15.3571 7.31175C15.5714 7.51148 16 8.39029 16 10.3077C14.2857 15.1012 4 16 2.5 16C1.3 15.5206 1 13.9028 1 13.3036C1 12.7045 4.21428 9.70851 4 9.40891C3.78571 9.10932 1.98571 7.25183 1.64286 7.01215C1.3 6.77248 1.35714 5.91364 1.42857 5.51418C1.35714 5.31445 1.77143 4.49556 4 2.81783Z" fill="${colour}" stroke="${strokeColour}"/></svg>`;
    }
}

function plantationStyleByAge(feature, resolution, filter){
    let styles = [];

    let fProp = feature.getProperties();

    let opacity = 1;

    var mapOpacity =  _opacityMap.get(filter);

    if(mapOpacity != null)
    {
        opacity = mapOpacity;
    }


    if(filter == null || filter == fProp.plant_year){
        let colour = [128, 128, 255, 1.0];
        let strokeColour = [255, 0, 255, 1.0];

        switch (fProp.plant_year) {
            case "Clearfell in Progress":
                colour = [0,112,255,opacity];
                strokeColour = [83,214,69];
                break;
            case "1990 & older":
                colour = [38,115,0,opacity];
                strokeColour = [83,214,69];
                break;

            case "1991-2001":
                colour = [76,230,0,opacity];
                strokeColour = [83,214,69];
                break;

            case "2001-2010":
                colour = [163,255,115,opacity];
                strokeColour = [83,214,69];
                break;

            case "2011-2021":
                colour = [233,255,190,opacity];
                strokeColour = [83,214,69];
                break;
            case "Planting in Progress":
                colour = [255,235,175,opacity];
                strokeColour = [255,189,56];
                break;
            case "Unplanted":
                colour = [255,190,190,opacity];
                strokeColour = [255,87,87];
                break;
            case "Regrowth":
                    colour = [255,190,232,opacity];
                    strokeColour = [255,87,215];
                    break;

            default:
                break;
        }

        styles.push(new Style({
            fill: new Fill({
                color: colour,
            }),
            stroke: new Stroke({
                width:0.5,
                color: strokeColour,
            }),
        }));
    }

    try{
        let ids = new Set();
        _hoverFeatures.forEach(f=>{
            ids.add(f.getProperties().gid)
        })
        if(ids.has(fProp.gid)){
            styles.push(new Style({
                stroke: new Stroke({
                    color: [128, 128, 255, 1.0],
                    width: 3
                }),
                zIndex:99
            }));
        }
       
    }
    catch(e){}
   
    return styles;
}

function plantationStyleByRotationLegend(name){
    
    if(name != null){

        let colour = "RGB(128, 128, 255, 1.0)";
        let strokeColour = "RGB(255, 0, 255, 1.0)";

        switch (name) {
            case "0R":
                colour = "RGB(190,210,255)";
                strokeColour = "RGB(16,120,255)";
                break;
            case "1R":
                colour = "RGB(190,255,232)";
                strokeColour = "RGB(138,244,216)";
                break;

            case "2R":
                colour = "RGB(211,255,190)";
                strokeColour = "RGB(88,255,4)";
                break;

            case "3R":
                colour = "RGB(255,255,190)";
                strokeColour = "RGB(255,255,16)";
                break;

            case "4R":
                colour = "RGB(255,190,190)";
                strokeColour = "RGB(255,16,16)";
                break;
        
            default:
                break;
        }

        return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="-3 -6 20 30"><path d="M4 2.81783C6.22857 1.14009 10.1429 0.920389 11.9286 1.02025C11.9286 1.02025 13 1.31985 12.3571 1.91904C11.7143 2.51823 11.2632 3.03858 10.8571 3.41701C9.82857 4.37572 10.4286 5.21458 10.8571 5.51418L15.3571 7.31175C15.5714 7.51148 16 8.39029 16 10.3077C14.2857 15.1012 4 16 2.5 16C1.3 15.5206 1 13.9028 1 13.3036C1 12.7045 4.21428 9.70851 4 9.40891C3.78571 9.10932 1.98571 7.25183 1.64286 7.01215C1.3 6.77248 1.35714 5.91364 1.42857 5.51418C1.35714 5.31445 1.77143 4.49556 4 2.81783Z" fill="${colour}" stroke="${strokeColour}"/></svg>`;
    }
}

export const turnAroundStyle = new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: 'red',
        width: 2
      }),
      fill: new Fill({color: 'white'})
    }),
    text: new TextStyle({
      text: 'T',
      fill: new Fill({
        color: 'red'
      }),
      font: '15px sans-serif',
      offsetY: 2.5
    })
  });

  export const waterwayStyle = new Style({
    image: new CircleStyle({
      radius: 10,
      stroke: new Stroke({
        color: 'blue',
        width: 2
      }),
      fill: new Fill({color: 'white'})
    }),
    text: new TextStyle({
      text: 'W',
      fill: new Fill({
        color: 'blue'
      }),
      font: '15px sans-serif',
      offsetY: 2.5
    })
  });

export  const privateRoadStyle = new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
      lineDash: [4, 8] // this will create a dashed line with a pattern of 4 pixels on and 8 pixels off
    })
  });
  



function MaiStylebByClass(feature, resolution, filter){
    let styles = [];

    let fProp = feature.getProperties();

    let opacity = 1;

    var mapOpacity =  _opacityMap.get(filter);

    if(mapOpacity != null)
    {
        opacity = mapOpacity;
    }


    if(filter == null || filter == fProp.mai_class){
        let colour = [128, 128, 255, 1.0];
        let strokeColour = [255, 0, 255, 1.0];

        switch (fProp.mai_class) {
            case 11:
                colour = [255,255,190,opacity];
                strokeColour = [83,214,69];
                break;
            case 13:
                colour = [209,255,115,opacity];
                strokeColour = [83,214,69];
                break;

            case 15:
                colour = [152,230,0,opacity];
                strokeColour = [83,214,69];
                break;

            case 17:
                colour = [56,168,0,opacity];
                strokeColour = [83,214,69];
                break;

            case 20:
                colour = [38,115,0,opacity];
                strokeColour = [83,214,69];
                break;
            default:
                break;
        }

        styles.push(new Style({
            fill: new Fill({
                color: colour,
            }),
        }));
    }

    try{
        let ids = new Set();
        _hoverFeatures.forEach(f=>{
            ids.add(f.getProperties().gid)
        })
        if(ids.has(fProp.gid)){
            styles.push(new Style({
                stroke: new Stroke({
                    color: [128, 128, 255, 1.0],
                    width: 3
                }),
                zIndex:99
            }));
        }
       
    }
    catch(e){}
   
    return styles;
}

function MaiStylebByClassLegend(miaClass){
    
    if(miaClass != null){

        let colour = "RGB(255,255,190, 1.0)";
        let strokeColour = "RGB(255, 0, 255, 1.0)";

        switch (miaClass) {
            case 11:
                colour = "RGB(255,255,190)";
                break;
            case 13:
                colour = "RGB(209,255,115)";
                break;

            case 15:
                colour = "RGB(152,230,0)";
                break;

            case 17:
                colour = "RGB(56,168,0)";
                break;

            case 20:
                colour = "RGB(38,115,0)";
                break;
        
            default:
                break;
        }

        return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="-3 -6 20 30"><path d="M4 2.81783C6.22857 1.14009 10.1429 0.920389 11.9286 1.02025C11.9286 1.02025 13 1.31985 12.3571 1.91904C11.7143 2.51823 11.2632 3.03858 10.8571 3.41701C9.82857 4.37572 10.4286 5.21458 10.8571 5.51418L15.3571 7.31175C15.5714 7.51148 16 8.39029 16 10.3077C14.2857 15.1012 4 16 2.5 16C1.3 15.5206 1 13.9028 1 13.3036C1 12.7045 4.21428 9.70851 4 9.40891C3.78571 9.10932 1.98571 7.25183 1.64286 7.01215C1.3 6.77248 1.35714 5.91364 1.42857 5.51418C1.35714 5.31445 1.77143 4.49556 4 2.81783Z" fill="${colour}" stroke="${strokeColour}"/></svg>`;
    }
}




function plantationHoverStyle(feature, resolution){
    let style = plantationStyle(feature, resolution);
    try{
        style = style.clone();
        let stroke = style.getStroke();
        stroke.setColor([128,128,255,1.0])
        stroke.setWidth(4);
        style.setStroke(stroke);
    }
    catch(e){}

 
    return style;
}

function roadStyle(feature, resolution, filter){
    let styles = [];
    let fProp = feature.getProperties();
    
    if(filter == null || filter == fProp.surface){
        switch (fProp.surface) {
            case 1:
                styles.push(new Style({
                    stroke: new Stroke({
                        color: [255,0,0,1.0],
                        width: 2
                    })
                }));
                break;
    
            case 2:
                styles.push(new Style({
                    stroke: new Stroke({
                        color: [255,170,0,1.0],
                        width: 1,
                        lineDash: [10,4]
                    })
                }));
                break;
    
            case 3:
                styles.push(new Style({
                    stroke: new Stroke({
                        color: [156,156,156,1.0],
                        width: 0.5
                    })
                }));
                break;
        
            default:
                break;
        }
    }

    return styles;
}


function gpsStyle(feature, resolution){
    let style = null;
    let geom = feature.getGeometry();

    if(geom instanceof Point){
        style = new Style({
            // f21d
            text: new TextStyle({
                text: '\uf21d',     //  fa-map-marker-alt
                font: '900 24px "Font Awesome 5 Free"',
                textBaseline: 'bottom',
                fill: new Fill({
                    color: [128,128,255,1.0],
                }),
                offsetY: 12,
                stroke: new Stroke({color: [0,0,0], width: 2}),
            })
        });
    }
    else{
        style =  new Style({
            stroke: new Stroke({
                color: [255,128,0,0.9],
                width: 2
            }),
            fill: new Fill({
                color: [255,128,0,0.4],
            })
        });
    }


    return style;
}

function surveyCellStyle(feature, resolution){
    let styles = [new Style({
        stroke: new Stroke({
            color: [0,0,0,0.70],
            width: 1
        }),
    }),
    new Style({
        stroke: new Stroke({
            color: [255,255,255,0.30],
            width: 2
        }),
    })
    ]

    try{
        let prop = feature.getProperties();

        let current_bookings = cellBookingCount[prop.cell_id] || 0;
        
        let r = cell_booking_colour_rgb[current_bookings][0];
        let g = cell_booking_colour_rgb[current_bookings][1];
        let b = cell_booking_colour_rgb[current_bookings][2];

        styles.push(new Style({
            stroke: new Stroke({
                color: [255,255,255,1.0],
                width: 2
            }),
            fill: new Fill({
                color: [r,g,b,cell_fill_opacity],
            })
        }));
    }catch(e){
        // console.log(e);
    }
    
    return styles;
}

function surveyCellHoverStyle(feature, resolution){

    let style = surveyCellStyle(feature, resolution);
    try{
        style = style.clone();
        let stroke = style.getStroke();
        stroke.setColor([128,128,255,1.0])
        stroke.setWidth(4);
        style.setStroke(stroke);
    }
    catch(e){}

    
    return style;
}


function surveyCellIDLabelStyle(feature, resolution){
    let cell_id = '';

    try {
        let prop = feature.getProperties();
        //force cast to string
        cell_id = '' + prop.cell_id
    } catch (error) {
        
    }

    let style = new Style({
        text: new TextStyle({
		    font: '14px "Open Sans", "Arial Unicode MS", "sans-serif"',
		    fill: new Fill({
		      color: 'rgba(0,0,0,1)'
		    }),
		    overflow: true,
		    stroke: new Stroke({
				color: 'rgba(255,255,255,0.8)',
				width: 2
			  }),
		    text: cell_id
		}),
    });

    return style;
}


function surveyCellVertexLabelStyle(feature, resolution){
    var styles = []
    feature
        .getGeometry()
        .getCoordinates()[0][0]//multi part but when know part needed
        .slice(0, -1)//remove repeated end
        .forEach(function(coordinates, index) {
            styles.push(
                new Style({
                    image: new Circle({
                        radius: 8,
                        fill: new Fill({
                            color: "orange"
                        })
                    }),
                    text: new TextStyle({
                        font: 'bold 14px "Open Sans", "Arial Unicode MS", "sans-serif"',
                        fill: new Fill({
                            color: 'rgba(0,0,0,1)',
                        }),
                        overflow: true,
                        stroke: new Stroke({
                            color: 'rgba(200,200,200,0.8)',
                            width: 2
                        }),
                        //Known ring order for data set Fist & Last of 4 at "bottom"
                        offsetY: (index%3 == 0?1:-1)*20,
                        text: formatCoordinates(coordinates)
                    }),
                    geometry: new Point(coordinates)
                })
            );
        });
    return styles;
}

export function formatCoordinates(originalCoordinates, targetEPSG='EPSG:4326'){
    let sourceEPSG = mapProjEPSG;
    let precision = 4;
    
    const sProj = proj4.defs[sourceEPSG];
    const tProj = proj4.defs[targetEPSG];
    
    const projected = proj4(sProj, tProj, originalCoordinates);

    if(targetEPSG != "EPSG:4326"){
        return 'X:'+projected[0].toFixed(1) + ' Y:'+projected[1].toFixed(1); 
    }

    return ''+ Math.abs(projected[1].toFixed(precision)) + (projected[1] >0? 'N, ': 'S, ') + projected[0].toFixed(precision)+'E';
}


export function surveyLegend(config, container, lyrId){
    let html = '<div class="legend_block"><div id="booked_cell_legend_block">';
    
    for(let i=0; i<cell_booking_colour_rgb.length; i++){
        let rgb = cell_booking_colour_rgb[i]
        html += '<div class="booked_cell_legend_row">';
            html += '<div id="booked_cell_legend_icon_'+i+'" style="background-color: ['+rgb[0]+','+rgb[1]+','+rgb[2]+')" class="booked_cell_legend_icon"></div>';
            html += '<div class="booked_cell_legend_text">'+i+' of '+ max_cell_bookings+' dates booked</div>';
        html += '</div>';
    };

    html += '</div></div>';

    let r = $(container).append(html);
}

export function getLayerOpacity(lyr){
    return lyr.getOpacity();
}

export function layerOpacityChange(value, lyr){

    lyr.changed();
}


//WORK IN PROGRESS ONLY
export function getLegendImage(layer, geomType="Polygon", feature) {
    const width = 40 * DEVICE_PIXEL_RATIO;
    const height = 40 * DEVICE_PIXEL_RATIO;

    let size = [width*0.9, height*0.9];

    let cx = width/2;
    let cy = height/2;
    let sx = size[0]/2;
    let sy = size[1]/2;

    let vectorContext = toContext(_canvas.getContext('2d'), {
      size: [width, height]
    });

    //TODO loop arrays etc, syle functions 
    //Need example feature list for style functions
    let styles = layer.getStyle();

    if (typeof styles === 'function')
    {
        styles = styles(feature,null);
    }

    if(!(styles instanceof Array)){
        styles = [styles];
    }

    for(let i=0; i<styles.length; i++) { 
        let style = styles[i]
        vectorContext.setStyle(style);
        let geom = null;
        switch (geomType) 
        {
            case "MultiPoint":
            case "Point":
                geom = Point([cx, cy]);
                break;

            case 'LineString':
            case 'MultiLineString': 
                geom = new LineString([[cx-sx, cy], [cx+sx, cy]]);
                break;
            case 'Roads':

            case "MultiPolygon":
            case "Polygon":
                // rectangle
                // let coordinates1 = [[[cx-sx, cy-sy], [cx+sx, cy-sy], [cx+sx, cy+sy], [cx-sx, cy+sy], [cx-sx, cy-sy]]];

                //SVG blob
                // let coordinates2 = [[[4.000,2.818],
                //     [11.929,1.020],
                //     [12.357,1.919],
                //     [10.857,3.417],
                //     [10.857,5.514],
                //     [15.357,7.312],
                //     [16.000,10.308],
                //     [2.500,16.000],
                //     [1.000,13.304],
                //     [4.000,9.409],
                //     [1.643,7.012],
                //     [1.429,5.514],
                //     [4.000,2.818]]];

                let scaleX = size[0] * 0.75;
                let scaleY = size[0] * 0.75;

                //Need x/y offsite for not square
                //SVG blob normalised to unit space
                let coordinates = [[
                    [10 + scaleX*0.1875000, scaleY*0.1123750],
                    [10 + scaleX*0.6830625, scaleY*0.0000000],
                    [10 + scaleX*0.7098125, scaleY*0.0561875],
                    [10 + scaleX*0.6160625, scaleY*0.1498125],
                    [10 + scaleX*0.6160625, scaleY*0.2808750],
                    [10 + scaleX*0.8973125, scaleY*0.3932500],
                    [10 + scaleX*0.9375000, scaleY*0.5805000],
                    [10 + scaleX*0.0937500, scaleY*0.9362500],
                    [10 + scaleX*0.0000000, scaleY*0.7677500],
                    [10 + scaleX*0.1875000, scaleY*0.5243125],
                    [10 + scaleX*0.0401875, scaleY*0.3745000],
                    [10 + scaleX*0.0268125, scaleY*0.2808750],
                    [10 + scaleX*0.1875000, scaleY*0.1123750]]];

                geom = new Polygon(coordinates);
                break;
        
            default:
                break;
        }

        vectorContext.drawGeometry(geom);
    }
 
    return _canvas.toDataURL();
  };

  export function getFillLayerOpacity(lyr){
      let opacity = 1; 

     var mapOpacity =  _opacityMap.get(lyr.values_.title);

     if(mapOpacity != null)
     {
        opacity = mapOpacity;
     }

    return opacity;
}

export function fillLayerOpacityChange(value, lyr){

 _opacityMap.set(lyr.values_.title,value);

    lyr.changed();
}

export function setLayerOpacityMap(map)
{
    _opacityMap = map;
}