//Needed for "async" support on ancient browsers
// import regeneratorRuntime from "regenerator-runtime";

import $, { map } from "jquery";

import 'ol/ol.css';
import '../style/ol-layerswitcher.css';
import '../style/basemap-control.css';
import 'sidebar-v2/css/ol3-sidebar.css';
import 'ol-contextmenu/dist/ol-contextmenu.css'
import '../style/map.sass';
import '../style/custom-controls.css';


import {Feature, Map, View, Geolocation} from 'ol';

import {pointerMove as eventPointerMove} from 'ol/events/condition';
import Collection from 'ol/Collection';

import * as olProj from 'ol/proj';
import proj4 from 'proj4';
import {mapProjEPSG} from './projection_setup'

import {Layer, Group as LayerGroup, Group} from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from "ol/geom/Point";

import Draw from 'ol/interaction/Draw';
import Overlay from 'ol/Overlay';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {LineString, Polygon} from 'ol/geom';
import {OSM} from 'ol/source';
import {Tile as TileLayer } from 'ol/layer';
import {getArea, getLength} from 'ol/sphere';
import {unByKey} from 'ol/Observable';
import GeoJSON from 'ol/format/GeoJSON';
import {defaults as defaultControls,Control, FullScreen, MousePosition, Attribution,ScaleLine} from 'ol/control';

import {defaults as defaultInteractions, DragPan, DragRotateAndZoom, PinchRotate, PinchZoom, Select} from 'ol/interaction';

import {SidebarPatch as Sidebar} from './sidebar_patch';
import LayerSwitcher from './ol-layerswitcher';

import ContextMenu from 'ol-contextmenu';
import {easeIn, easeOut} from 'ol/easing';
import { none } from "ol/centerconstraint";

import PopperLabel from './PopperLabel'
import PopperHelpSet from './PopperHelp'
import { Popover } from 'bootstrap';

var { populateLayerGroup } = require('./LayerBuilder');
const { base_map_defs } = require('./Basemaps');
const { other_map_defs } = require('./OtherLayers');
const { mapStyles, setHoverFeaturesForVTStyle } = require('./mapStyles')


const {CWFHLayerGroup, softwoodGroup,otherSoftwood, buildHaulage} = require('./CWFHLayers');

/**
 * Currently drawn feature.
 * @type {import("../src/ol/Feature.js").default}
 */
 var sketch;

 /**
  * The help tooltip element.
  * @type {HTMLElement}
  */
 var helpTooltipElement;
 
 /**
  * Overlay to show the help messages.
  * @type {Overlay}
  */
 var helpTooltip;
 
 /**
  * The measure tooltip element.
  * @type {HTMLElement}
  */
 var measureTooltipElement;
 
 /**
  * Overlay to show the measurement.
  * @type {Overlay}
  */
 var measureTooltip;
 
 /**
  * Message to show when the user is drawing a polygon.
  * @type {string}
  */
 var continuePolygonMsg = 'Click to continue drawing the polygon';
 
 /**
  * Message to show when the user is drawing a line.
  * @type {string}
  */
 var continueLineMsg = 'Click to continue drawing the line';
 

var proj = olProj.get(mapProjEPSG);
var measureToolTipArray = [];
let ol_map = null;
let hoverMapFeatures = new Collection();
let hoverFeatures = new Set();
let scaleType = 'scaleline';
let scaleBarSteps = 4;
let scaleBarText = true;
let control;
var draw; // global so we can remove it later

let helpPoppers = null;

setHoverFeaturesForVTStyle(hoverFeatures);


let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
let isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

const urlParams = new URLSearchParams(window.location.search);


function initMap(){
  setupMap();
 

}

var ClearScreenControl = /*@__PURE__*/(function (Control) {
  function ClearScreenControl(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    button.className = "clear-screen-button walkthrough";
    button.title = "Clear Screen";
    button.id = "clearScreenControl";
    button.setAttribute("data-wtpos", "In");
    button.setAttribute("data-wtnr", 1);
    button.setAttribute("data-wtnext", "Next");
    button.setAttribute("data-wttext", "Select this tool to enable screen pan.");

    var element = document.createElement('div');
    element.className = 'clear-screen ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.clearScreen.bind(this), false);
  }

  if ( Control ) ClearScreenControl.__proto__ = Control;
  ClearScreenControl.prototype = Object.create( Control && Control.prototype );
  ClearScreenControl.prototype.constructor = ClearScreenControl;

  ClearScreenControl.prototype.clearScreen = function clearScreen () {
    measureToolTipArray.forEach(element => ol_map.removeOverlay(element));
    source.clear();
  };

  return ClearScreenControl;
}(Control));



var PanControl = /*@__PURE__*/(function (Control) {
  function PanControl(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    button.className = "pan-screen-button tool-active walkthrough";
    button.title = "Pan";
    button.id = 'panControl';
    button.setAttribute("data-wtpos", "in");
    button.setAttribute("data-wtnr", 4);
    button.setAttribute("data-wtnext", "Next");
    button.setAttribute("data-wttext", "Select this tool to enable screen pan.");


    var element = document.createElement('div');
    element.className = 'pan-screen ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.pan.bind(this), false);
  }

  if ( Control ) PanControl.__proto__ = Control;
  PanControl.prototype = Object.create( Control && Control.prototype );
  PanControl.prototype.constructor = PanControl;
  PanControl
  PanControl.prototype.pan = function pan (e) {

    let drawInteraction = ol_map.interactions.array_.find(element => element == draw);;

    if(drawInteraction != null)
    {
        
        $("#measureLineControl").removeClass( "tool-active" );
        $("#measureAreaControl").removeClass( "tool-active" );
        ol_map.removeInteraction(draw);
    }

    e.currentTarget.classList.add("tool-active");

  };

  return PanControl;
}(Control));


var MeasureAreaControl = /*@__PURE__*/(function (Control) {
  function MeasureAreaControl(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    button.className = 'measure-area-button walkthrough';
    button.title = "Measure Area Tool";
    button.id = 'measureAreaControl';
    button.setAttribute("data-wtpos", "In");
    button.setAttribute("data-wtnr", 2);
    button.setAttribute("data-wtnext", "Next");
    button.setAttribute("data-wttext", "Select this tool to enable screen pan.");


    var element = document.createElement('div');
    element.className = 'measure-area ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.measureArea.bind(this), false);
  }

  if ( Control ) MeasureAreaControl.__proto__ = Control;
  MeasureAreaControl.prototype = Object.create( Control && Control.prototype );
  MeasureAreaControl.prototype.constructor = MeasureAreaControl;

  MeasureAreaControl.prototype.measureArea = function measureArea (e) {

    let type = 'Polygon';

    let drawInteraction = ol_map.interactions.array_.find(element => element == draw);;

    if(drawInteraction != null)
    {
      if(drawInteraction.mode_ == type)
      {
        e.currentTarget.classList.remove("tool-active");
        ol_map.removeInteraction(draw);
      }
      else
      {
        $("#measureLineControl").removeClass( "tool-active" );
        ol_map.removeInteraction(draw);
        addMeasureInteraction(type);
        e.currentTarget.classList.add("tool-active");
      }
    }
    else
    {
      addMeasureInteraction(type);
      e.currentTarget.classList.add("tool-active");
      $("#panControl").removeClass( "tool-active" );
    }
  };

  return MeasureAreaControl;
}(Control));


var MeasureLineControl = /*@__PURE__*/(function (Control) {
  function MeasureLineControl(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    button.innerHTML = '';
    button.className = 'measure-line-button walkthrough'
    button.title = "Measure Line Tool";
    button.id = "measureLineControl";
    button.setAttribute("data-wtpos", "In");
    button.setAttribute("data-wtnr", 3);
    button.setAttribute("data-wtnext", "Next");
    button.setAttribute("data-wttext", "Select this tool to enable screen pan.");

    var element = document.createElement('div');
    element.className = ' ol-control measure-line';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.measureLine.bind(this), false);
  }

  if ( Control ) MeasureLineControl.__proto__ = Control;
  MeasureLineControl.prototype = Object.create( Control && Control.prototype );
  MeasureLineControl.prototype.constructor = MeasureLineControl;

  MeasureLineControl.prototype.measureLine = function measureLine (e) {

   
   // e.currentTarget.parentElement.classList.add("tool-active");

    let type = 'LineString';

    let drawInteraction = ol_map.interactions.array_.find(element => element == draw);;

    if(drawInteraction != null)
    {
      if(drawInteraction.mode_ == type)
      {
        e.currentTarget.classList.remove("tool-active");
        ol_map.removeInteraction(draw);
      }
      else
      {
        ol_map.removeInteraction(draw);
        $( "#measureAreaControl" ).removeClass( "tool-active" );
        
        addMeasureInteraction(type);
        e.currentTarget.classList.add("tool-active");
      }
    }
    else
    {
      addMeasureInteraction(type);
      e.currentTarget.classList.add("tool-active");
      $("#panControl").removeClass( "tool-active" );
    }
  };

  return MeasureLineControl;
}(Control));


function scaleControl() {
  if (scaleType === 'scaleline') {
    control = new ScaleLine({
      units: 'metric',
    });
    return control;
  }
}


var source = new VectorSource();

var vector = new VectorLayer({
  source: source,
  style: new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.7)',
    }),
    stroke: new Stroke({
      color: '#ffcc33',
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: '#ffcc33',
      }),
    }),
  }),
});



/**
 * Handle pointer move.
 * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
 */
var pointerMoveHandler = function (evt) {
  if (evt.dragging) {
    return;
  }
  /** @type {string} */
  var helpMsg = 'Click to start drawing';

  if (sketch) {
    var geom = sketch.getGeometry();
    if (geom instanceof Polygon) {
      helpMsg = continuePolygonMsg;
    } else if (geom instanceof LineString) {
      helpMsg = continueLineMsg;
    }
  }

  helpTooltipElement.innerHTML = helpMsg;
  helpTooltip.setPosition(evt.coordinate);

  helpTooltipElement.classList.remove('hidden');
};



/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
var formatLength = function (line) {
  var length = getLength(line);
  var output;
  if (length > 1000) {
    output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
  } else {
    output = Math.round(length * 100) / 100 + ' ' + 'm';
  }
  return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = Math.round((area + Number.EPSILON) / 10000) + ' ' + 'ha';
    } else {
    output = (Math.round((area + Number.EPSILON) / 10) / 1000 ) + ' ' + 'ha';
    }
  return output;
};

function addMeasureInteraction(type) {
  draw = new Draw({
    source: source,
    type: type,
    style: new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.7)',
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.7)',
        }),
      }),
    }),
  });
  ol_map.addInteraction(draw);

  createMeasureTooltip();
  createHelpTooltip();

  var listener;
  draw.on('drawstart', function (evt) {
    // set sketch
    sketch = evt.feature;

    /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
    var tooltipCoord = evt.coordinate;

    listener = sketch.getGeometry().on('change', function (evt) {
      var geom = evt.target;
      var output;
      if (geom instanceof Polygon) {
        output = formatArea(geom);
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      } else if (geom instanceof LineString) {
        output = formatLength(geom);
        tooltipCoord = geom.getLastCoordinate();
      }
      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);
    });
  });

  draw.on('drawend', function () {
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
    measureTooltip.setOffset([0, -7]);
    // unset sketch
    sketch = null;
    // unset tooltip so that a new one can be created
    measureTooltipElement = null;
    createMeasureTooltip();
    unByKey(listener);
  });
}

/**
 * Creates a new help tooltip
 */
function createHelpTooltip() {
  if (helpTooltipElement) {
    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
  }
  helpTooltipElement = document.createElement('div');
  helpTooltipElement.className = 'ol-tooltip hidden';
  helpTooltip = new Overlay({
    element: helpTooltipElement,
    offset: [15, 0],
    positioning: 'center-left',
  });
  ol_map.addOverlay(helpTooltip);
}

/**
 * Creates a new measure tooltip
 */
function createMeasureTooltip() {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center',
  });

  measureToolTipArray.push(measureTooltip);

  ol_map.addOverlay(measureTooltip);
}





async function setupMap()
{
  let layerSwitcher = new LayerSwitcher({reverse:true});
  let fullscreenControl = new FullScreen({});

  let attribution = new Attribution({
    collapsible: false,
    target: 'layer_atribution'
  });

  ol_map = new Map({
    controls: defaultControls({navigator:true, attribution: false, rotate:false ,zoom:true}).extend([
      attribution,
      fullscreenControl,
      layerSwitcher,
      sidebar,
      contextmenu,
      new ClearScreenControl(),
      new MeasureAreaControl(),
      new MeasureLineControl(),
      new PanControl(),
      new scaleControl(),
    ]),
    interactions: defaultInteractions({pinchRotate:false}).extend([
      // new DragPan(),
      // new PinchZoom()
    ]),
    layers: [ //Bottom up order
      basemapGroup,
      otherLayersGroup,
      CWFHLayerGroup,
       vector
    ],
    target: 'map',
    view: new View({
      //3857 PM
      center: [16680338,-4008536],
      //  3308 NSW LC
      // center:[9536060,4432510],
      projection: proj,
      zoom: 9,
      maxZoom: 23
    }),
    pixelRatio : window.devicePixelRatio,
    maxTilesLoading: 256,
  });
  window.ol_map = ol_map;

  $(document).on("keydown",function(e)
  {
    if (e.key === "Escape") { // escape key maps to keycode `27`
     if(ol_map.interactions.array_.includes(draw))
     {
      $( "#measureAreaControl" ).removeClass( "tool-active" );
      $( "#measureLineControl" ).removeClass( "tool-active" );
       ol_map.removeInteraction(draw);
     }
  }
  });

  // ol_map.getViewport().addEventListener('mouseout', function () {
  //   helpTooltipElement.classList.add('hidden');
  // });

  ol_map.on('moveend',rebuildLegend)

  // let selectTool = new Select({
 

  if(!isMobile){

    let hoverGroups = [softwoodGroup,otherSoftwood];

    let hoverLayerArray;
    hoverGroups.forEach((group) => {
      if(group instanceof LayerGroup)
      {
        hoverLayerArray = group.getLayersArray();
      }
      else
      {
        let temp = group.getLayersArray();

        temp.forEach(l => {
          hoverLayerArray.push(l);
        })
      }
    })

    let selectHoverToolLayerGroup = new Select({
      condition: eventPointerMove,
      multi : true,
      layers: hoverLayerArray,
      features: hoverMapFeatures,
      style: null,
      hitTolerance: 0
    });
    
    ol_map.addInteraction(selectHoverToolLayerGroup);
    selectHoverToolLayerGroup.on('select',hoverOnFeature);


    ol_map.addControl(mousePositionControl);
  }
  
let populatePromiseGroup = [populateLayerGroup(base_map_defs, basemapGroup, null),  populateLayerGroup(other_map_defs, otherLayersGroup, null),buildHaulage()];


Promise.all(populatePromiseGroup).then(rebuildLegend);

  //Move to generic function, callbacks?
  window.onresize = ()=>{
    //TODO Change to full screen under floating banner, behaves much better cross device
    let scrollTo = 0;
    if(isIOS){
      if(window.orientation == 0 || window.orientation == 180){
        //iPhone bars vary by iOS... works well enough on most for now...
        scrollTo = window.screen.height;
        // $('#sidebar_div').css('bottom', 50);
      }
      else{
        //Landscape "full screen as bars auto hide"
        //Check iPad?
        scrollTo = window.screen.height;
        // $('#sidebar_div').css('bottom', 0);
      }
    }

    setTimeout( ()=>{
      ol_map.updateSize();
      window.scrollTo(0,scrollTo);
    }, 200);

    setTimeout(()=>{
      ol_map.updateSize();
    },2000);
  }

  window.addEventListener("orientationchange", ()=>{
    ol_map.updateSize();
    setTimeout(()=>{
      ol_map.updateSize();
    },2000);
  });

  ol_map.once('precompose', postMapLoad);

  if(isIOS){
    window.onorientationchange = event=>{
      setTimeout( ()=>{window.onresize();}, 500);
    };

    setTimeout( ()=>{window.onresize();},1500);
  }

  if(isMobile){
    $("#mouse-position").css('display','none');
  }

  $('#loading_message').hide();
  $('#map').removeClass('invisible');
  $('#map').addClass('animate_visible');

  setupHelp();
}



function changeZoomExtent(extent){
	//Target is for horizontal extent to be 30m
	let leftExtent = extent[0];
	let rightExtent = extent[2];
	let bottomExtent = extent[1];
	let topExtent = extent[3];

	let horizontalDiff = rightExtent - leftExtent;
	let horizontalAdjustmentNeeded = (40 - horizontalDiff) / 2;

	let verticalDiff = topExtent - bottomExtent;
	let verticalAdjustmentNeeded = (40 - verticalDiff) / 2;

	let adjustedLeftExtent = leftExtent - horizontalAdjustmentNeeded;
	let adjustedRightExtent = rightExtent + horizontalAdjustmentNeeded;
	let adjustedTopExtent = topExtent + verticalAdjustmentNeeded;
	let adjustedBottomExtent = bottomExtent - verticalAdjustmentNeeded;

	return [adjustedLeftExtent, adjustedBottomExtent, adjustedRightExtent, adjustedTopExtent];
}


function rebuildLegend(){
  LayerSwitcher.renderPanel(ol_map,document.getElementById("layers"));
 
}

function selectFeature(e){
  try{
    selectedSurveyCell = e.selected[0];
    selectedDateIDs.clear();
    tacAccept = false;
  
    if(!selectedSurveyCell){
      $("#bookCellNoSelection").show();
      $("#bookCell").empty().hide();
      try{
        if(sidebar.getActive()){
          if(sidebar.getActive().id == "book_cell") {
            sidebar.close();
          }	
        }
      }catch(e){}
    }
    else{
      getSurveyCellInfo(selectedSurveyCell);
      // focusOnFeatureAdjust(FOCUS_ZOOM_LEVEL, selectedSurveyCell);
    }
  }catch(e){
    console.log(e);
  }
}


function hoverOnFeature(e){
  try{
		e.selected.forEach((feature)=>{
			hoverFeatures.add(feature);
		});
	} catch(e){console.log("Error adding selection to hover features list"); console.log(e);}

	try{
		e.deselected.forEach((feature)=>{
			hoverFeatures.delete(feature);
		});
  } catch(e){console.log("Error removing deselected feature from hover features list"); console.log(e);}

  
  updateHoverInfo(Array.from(hoverFeatures));

  //hard code for style required by Vector tiles
otherSoftwood.changed();

  softwoodGroup.getLayers().forEach(lg=>{
    lg.getLayers().forEach(l=>{
      l.changed();
    })
  });
  
}

async function updateHoverInfo(features){
  let html = '';
  //only works if one layer or ids are global unique
  let shown_gids = new Set();
  

  for(var i =0; i < features.length; i++)
  {
    try{
      let fProp = features[i].getProperties();
      if(shown_gids.has(fProp.gid)){
      continue;
      }
      let area,owner,species,agegroup,rotation,gid,plantYear;

      if(fProp.hasOwnProperty("Area_Ha"))
      {
         area = Math.round(fProp.Area_Ha*100)/100 + 'ha'; 
      }
      else
      {
         area = Math.round(fProp.hectares*100)/100 + 'ha'; 
      }

      if(fProp.hasOwnProperty("species"))
      {
       species = fProp.species;
      }
      else
      {
        species = fProp.Species;
      }

      if(fProp.hasOwnProperty("owner"))
      {
        owner = fProp.owner
      }
      else
      {
        owner = "FCNSW"
      }

      if(fProp.hasOwnProperty("rotation"))
      {
        rotation = fProp.rotation;
      }
      else
      {
        rotation = fProp.Rotation;
      }

      if(fProp.hasOwnProperty("agegroup"))
      {
        agegroup = fProp.agegroup;
      }
      else
      {
        agegroup = fProp.AgeGroup;
      }
      if(fProp.hasOwnProperty("gid"))
      {
        gid = fProp.gid;
      }
      if(fProp.hasOwnProperty("plant_year"))
      {
        plantYear = fProp.plant_year;
      }
      else
      {
        plantYear = 'Undefined';
      }

      html += '<p>' + 'Polygon ID: ' + gid + '</p>'
      html += '<p>' + owner + ' - ' + species + '</p>'
      html += '<p>' + 'Planted: ' + plantYear + '</p>'
      html += '<p>' +' (' + rotation + ')</p>'
      html += '<p>' + area + '</p>'
      
      shown_gids.add(fProp.gid)
      
      }catch(e){
      console.log(e);
      }
  }

  
  $('#map_hover_info').empty().append(html);
 }


function postMapLoad(){
  $('#loading_message').hide();
  $('#map').show();
  if(isMobile){
    window.onresize();
  }
}


function formatMouseCoordinates(originalCoordinates, targetEPSG=null){
  try{
    if(!targetEPSG){
      targetEPSG = mousePositionControl.projEPSG;
    }
    
    let sourceEPSG = mapProjEPSG;

    try{
      sourceEPSG = mousePositionControl.getMap().getView().getProjection().getCode();
    }
    catch(e){}

    const sProj = proj4.defs[sourceEPSG];
    const tProj = proj4.defs[targetEPSG];

    const projected = proj4(sProj, tProj, originalCoordinates);
    
    if(targetEPSG != "EPSG:4326"){
        return 'X:'+projected[0].toFixed(1) + ' Y:'+projected[1].toFixed(1); 
    }
    else{
        return 'lat:'+projected[1].toFixed(6) + ' lon:'+projected[0].toFixed(6);
    }
  }
  catch(e){
    return '';
  }
}


const basemapGroup = new LayerGroup({
    title: 'Basemaps',
    layers: [],
    'fold': 'open',
    hideInLegend: true,
  });

const otherLayersGroup = new LayerGroup({
  title: 'Other Layers',
  layers: [],
  'fold': 'open'
});
  
  
const mousePositionControl = new MousePosition({
  projection: proj,//'EPSG:28355',
  coordinateFormat: formatMouseCoordinates,
  className: 'custom-mouse-position',
  target: document.getElementById('mouse-position'),
  undefinedHTML: ''
});
  
mousePositionControl.projEPSG = 'EPSG:4326';

const sidebar = new Sidebar({ element: 'sidebar_div', position: 'right' });
sidebar.setOpenCallback(sidebarToggleOpen);
sidebar.setCloseCallback(sidebarToggleClose);

function toggleList(e){
	e.preventDefault();
	  
	var $this = $(this);
  
	if ($this.next().hasClass('show')) {
		$this.next().removeClass('show');
		$this.next().slideUp(300);
	}
	else {
		$this.parent().parent().find('li .inner').removeClass('show');
		$this.parent().parent().find('li .inner').slideUp(300);
		$this.next().toggleClass('show');
		$this.next().slideToggle(300);
	}
}


const locationMenuItem = {
  text: 'Coordinates',
  classname: 'some-style-class', // add some CSS rules
  callback: null // `center` is your callback function
};

const contextmenu_items = [
  locationMenuItem,
  '-',
  {
    text: 'Center map here',
    classname: 'some-style-class', // add some CSS rules
    callback: centerMap // `centerMap` is your callback function
  },
];

const contextmenu = new ContextMenu({
  width: 170,
  defaultItems: true, // defaultItems are (for now) Zoom In/Zoom Out
  items: [
    locationMenuItem,
  ]
});



function centerMap(obj) {
  ol_map.getView().animate({
    duration: 700,
    easing: easeOut,
    center: obj.coordinate,
  });
}

contextmenu.on('open', (evt)=>{
  const coordinate = ol_map.getCoordinateFromPixel(evt.pixel);

  locationMenuItem.text = formatMouseCoordinates(coordinate);

  contextmenu.clear();
  contextmenu.extend(contextmenu_items);
  contextmenu.extend(contextmenu.getDefaultItems());
  // console.log(coordinate);
});



function focusOnFeatureAdjust(newzoom, feature){
  let olMapView = ol_map.getView();
  let new_sidebar_width = $('.sidebar-content').width();
	let covered = (new_sidebar_width)/$('body').width();

  if(covered < 0.7){
    olMapView.padding = [0,new_sidebar_width,0,0];
  }
  else{
    olMapView.padding = [0,0,0,0];
  }
  

  let newx;
  let newy;
  let mapEx = olMapView.calculateExtent();

  if(feature){
    let e = feature.getGeometry().getExtent();
    newx = (e[0]+e[2])/2;
    newy = (e[1]+e[3])/2;
  }
  else{
    newx = (mapEx[0] + mapEx[2])/2;
    newy = (mapEx[1] + mapEx[3])/2;
  }

  olMapView.animate({
    center: [newx, newy],
    duration: 1500,
    // easing: ol.easing.easeIn
  });

  olMapView.animate({
    zoom: newzoom,
    duration: 1500,
    easing: easeIn
  });

}


function sidebarToggleOpen(openID, closeID){
}

function sidebarToggleClose(){

}


window.initMap = initMap;
window.$ = $;

  // Toggle Basemaps Gallery Visibility
  $(".basemaps-selector").on("click", function() {
    $(".basemaps-gallery").toggleClass('d-none');
});

// Switch active Basemap on click of Basemap Thumbnail in Gallery
$(".basemaps-gallery-container").on("click", function() {
    changeBaseMap($(this).attr("basemapid"));
    changeActiveBaseMapImg($(this).children()[0].src);
});

// Change visible Map Basemap on User Selection
function changeBaseMap(basemapName) {
    ol_map.getLayers().forEach(function (layer) {
        if (layer.get('title') == 'Basemaps') {
            layer.getLayers().forEach(function (basemap) {
                if (basemap.get('title') == basemapName) {
                    basemap.setVisible(true);
                } else {
                    basemap.setVisible(false);
                }
            })
        }
    })
}

//Change the active icon to the newly selected base map. 

function changeActiveBaseMapImg(src)
{
  $('#active_basemap_tile')[0].src = src;
}

window.sidebar = sidebar;


async function setupHelp(){

  if(isMobile) return;
  //check for cookie, only showon first visit and explicit help press?
  helpPoppers = new PopperHelpSet();

  helpPoppers.addElement('clearScreenControl',{
    content:'Click here to clear the screen of any measurements.',
    popper_placement: 'right'
  });

  helpPoppers.addElement('measureAreaControl',{
    content:'Click here to start a measuring by area, you can stop by pressing ESC or clicking here again.',
    popper_placement: 'right'
  });
  helpPoppers.addElement('measureLineControl',{
    content:'Click here to start a measuring by line, you can stop by pressing ESC or clicking here again.',
    popper_placement: 'right'
  });
  helpPoppers.addElement('panControl',{
    content:'Click here to activate the pan control, this de-activates any other controls selected (I.E area measure tool).',
    popper_placement: 'right'
  });


  helpPoppers.addElement('active_basemap_tile',{
    content:'Click here to change what basemap is used.',
    title:'BaseMap Selector',
    popper_placement: 'right'
  });

  helpPoppers.addElement('manage_layers_link',{
    content:'Click here to manage the visible layers',
    title:'Manage Layers',
    popper_placement: 'right'
  });

  helpPoppers.addElement('home',{
    content:'Turn on or off layers here<br>\
Some detailed layers can only be seen when zoomed in',
title:'Layers',
    popper_placement: 'left',

    preShowCallback: ()=>{
      sidebar.open('home')
    },
    postShowCallback:() =>{
      setTimeout(() => {
        helpPoppers.update();
      }, 100);
      
    },
    postHideCallback: ()=>{
      sidebar.close();
    }
  });
  
  const helpShownKey = "helpShown";
  const helpShown = localStorage.getItem(helpShownKey);

  if (!helpShown){
    showHelp()
    localStorage.setItem(helpShownKey,true);
  }

  function CheckDisclaimer()
  {
      
      let diclaimerTag = localStorage.getItem('disclaimer');
  
      if(diclaimerTag == null)
      {
          localStorage.setItem('disclaimer', "false");
      }
      else
      {
          if(diclaimerTag == "false")
          {
              ToggleDisclaimer();
          }
         
      }
  
  }
  
  function ToggleVisibility(element)
  {
      if(element.classList.contains("hide"))
      {
          element.classList.remove("hide");
          element.classList.add("show");
  
      }
  
      else { 
          element.classList.remove("show");
          element.classList.add("hide");
      }
  
  }
  
  
  
  window.onload = function() {
    };
  
    let disclaimerButton = document.getElementById("disclaimer_button");
  
    disclaimerButton.addEventListener("click", () => { 
  
      ToggleDisclaimer();
      localStorage.setItem('disclaimer', "true");
  
    });
  
  
  
    function ToggleDisclaimer()
    {
      let disclaimer = document.getElementById("disclaimer");
      let disclaimerBackgeound =  document.getElementById("disclaimer_background");
  
      ToggleVisibility(disclaimerBackgeound);
      ToggleVisibility(disclaimer);
    }

  $('#helpTrigger').on('click', showHelp);
}

async function showHelp(){
  helpPoppers.startHelp();
}
