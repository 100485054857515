import * as olProj from 'ol/proj';
import proj4 from 'proj4';
import {mapProjEPSG} from './projection_setup'

import GeoJSON from 'ol/format/GeoJSON';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import Feature from 'ol/Feature'

import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';

import {Layer, Group as LayerGroup} from 'ol/layer';
import {Fill, Stroke, Circle, RegularShape, Style, Text as TextStyle, Icon} from 'ol/style';

const {fillLayerOpacityChange, getFillLayerOpacity,setLayerOpacityMap} = require('./mapStyles')

const { legendImages, getLegendImage, mapStyles,getLayerOpacity, layerOpacityChange,turnAroundStyle,waterwayStyle ,privateRoadStyle} = require('./mapStyles')
var proj = olProj.get(mapProjEPSG);

const geoJSONMapProj = new GeoJSON({dataProjection:proj, featureProjection:proj});

let systemBase = '.';

//Dev Mode
let hostSubStr = window.location.host.substring(0,9)

//window.location.protocol not http https
if(hostSubStr == "127.0.0.1" || hostSubStr== "localhost" || window.location.protocol.substring(0,4) != 'http' ){
  systemBase = 'https://cwfhtest.eskmapping.com.au';
}

var opacityMap = new Map();

const haulageGroup = new LayerGroup({
    title: 'Haulage',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    visible: false
});


const strokeMapping = {
    '0 - 50,000': new Style({ stroke: new Stroke({color: [2,255,197,1.0], width: 0.75})}),
    '50,001 - 100,000': new Style({ stroke: new Stroke({color: [66,242,144,1.0], width: 1.5})}),
    '100,001 - 500,000': new Style({ stroke: new Stroke({color: [89,237,124, 1.0], width: 2})}),
    '500,001 - 1,000,000': new Style({ stroke: new Stroke({color: [162,221,63, 1.0], width: 2.5})}),
    '1,000,001 - 2,000,000': new Style({ stroke: new Stroke({color: [161,217,76, 1.0], width: 3})}),
    '2,000,001 - 3,000,000': new Style({ stroke: new Stroke({color: [211,211,23, 1.0], width: 3.5})}),
    '3,000,001 - 4,000,000': new Style({ stroke: new Stroke({color: [220,179,18, 1.0], width: 4})}),
    '4,000,001 - 5,000,000': new Style({ stroke: new Stroke({color: [234,103,10, 1.0], width: 4.5})}),
    '5,000,001 - 6,000,000': new Style({ stroke: new Stroke({color: [240, 46, 46, 1.0], width: 5})}),
    '6,000,001 - 6,500,000': new Style({ stroke: new Stroke({color: [240, 46, 46, 1.0], width: 5.5})}),
    '6,000,001 - 7,000,000': new Style({ stroke: new Stroke({color: [240, 46, 46, 1.0], width: 6})}),
    '7,000,001 - 8,000,000': new Style({ stroke: new Stroke({color: [240, 46, 46, 1.0], width: 6.5})}),
    '8,000,001 - 9,000,000': new Style({ stroke: new Stroke({color: [240, 46, 46, 1.0], width: 7})}),
    '9,000,001 - 10,000,000': new Style({ stroke: new Stroke({color: [240, 46, 46, 1.0], width: 7.5})}),
};



const HaulageGroupDefinitions = [
    {
        title: 'Volume Summary 2041-2050',
        propertyName: 'TOT_VOL_22',
        filter: ['0 - 50,000', '50,001 - 100,000', '100,001 - 500,000', '500,001 - 1,000,000', '1,000,001 - 2,000,000', '2,000,001 - 3,000,000', '3,000,001 - 4,000,000', '4,000,001 - 5,000,000', '5,000,001 - 6,000,000','6,000,001 - 7,000,000','7,000,001 - 8,000,000','8,000,001 - 9,000,000','9,000,001 - 10,000,000'],
        visible: false,
    }, 
    {
        title: 'Volume Summary 2031-2040',
        propertyName: 'TOT_VOL_21',
        filter: ['0 - 50,000', '50,001 - 100,000', '100,001 - 500,000', '500,001 - 1,000,000', '1,000,001 - 2,000,000', '2,000,001 - 3,000,000', '3,000,001 - 4,000,000', '4,000,001 - 5,000,000', '5,000,001 - 6,000,000','6,000,001 - 7,000,000'],
        visible:false
    }, 
    {
        title: 'Volume Summary 2021-2030',
        propertyName: 'TOT_VOL_20',
        filter: ['0 - 50,000', '50,001 - 100,000', '100,001 - 500,000', '500,001 - 1,000,000', '1,000,001 - 2,000,000', '2,000,001 - 3,000,000', '3,000,001 - 4,000,000', '4,000,001 - 5,000,000', '5,000,001 - 6,000,000','6,000,001 - 6,500,000'],
        visible:true
    }, 

]


export const CWFHLayerGroup = new LayerGroup({
    title: 'CWFH',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    visible: true
});

export async function buildHaulage()
{
return  fetch('./static/Haulage.geojson').then( async data => {

    const geoJson = await data.json();

    HaulageGroupDefinitions.reverse().forEach(definition => {

        let layerGroup = new LayerGroup({
            title: definition.title,
            layers: [],
            'fold': 'close',
            'group_visibility' : true,
            'toggle_all' : true,
            visible: definition.visible
        });
        

        definition.filter.forEach((filter, index) => 
            {
                let regex = /\d{1,3}(?:,\d{3})*|\d+/g;
                let matches = filter.match(regex);
                let numberArray = matches.map(num => Number(num.replace(/,/g, "")));

                const filteredFeatures = geoJson.features.filter(feature => feature.properties[definition.propertyName] > numberArray[0] && feature.properties[definition.propertyName] <= numberArray[1]);

                var testf = new GeoJSON().readFeatures({crs: geoJson.crs, name: geoJson.name, type: geoJson.type, features:filteredFeatures}); 
                let layer = new VectorLayer({
                    title: filter,
                    enableOpacitySliders: true,
                    opacityLabel: "Opacity",
                    style: (feature, resolution)=>{var a=  strokeMapping[filter]
                    return a
                    },
                    source: new VectorSource({features: testf}),
                    updateWhileAnimating: true,
                    zIndex:999
            })

            layer.setProperties({'legendImageSrc': getLegendImage(layer, 'LineString', new Feature({TOT_VOL_20:numberArray[1]}))});
            layerGroup.getLayers().push(layer);
            });
            generateHaulageLegendPopup(layerGroup);
            layerGroup.on('change:visible',haulageChangeVisibleGroup);
            haulageGroup.getLayers().push(layerGroup);
    });

})

}

const customers = new VectorLayer({
    title: 'Customers',
    legendImageSrc: legendImages.customer,
    style: mapStyles.customerStyle,
    source: new VectorSource({
        format: geoJSONMapProj,
        projection: proj,
        url: './static/Customers.geojson'

    }),
    updateWhileAnimating: true,
    zIndex:999
});


export const otherSoftwood = new VectorLayer({
    title: 'Other Softwood',
    style: (feature, resolution) => {return mapStyles.otherSoftwoodStyle(feature,resolution)},
    source: new VectorSource({
        format: geoJSONMapProj,
        projection: proj,
        url: './static/Other_Softwood.geojson'
    }),
    updateWhileAnimating: true,
    zIndex:999
});

otherSoftwood.setProperties({'legendImageSrc': getLegendImage(otherSoftwood,'Polygon',new Feature({Area_Ha:1}))});


export const  WaterPoints = new VectorLayer({
    title: 'Water Points',
    style:waterwayStyle,
    source: new VectorSource({
        format: geoJSONMapProj,
        projection: proj,
        url: './static/Waterpoints.geojson'
    }),
    updateWhileAnimating: true,
    zIndex:999
});

export const  PrivateRoads = new VectorLayer({
    title: 'Additional Mapped Roads',
    style: privateRoadStyle,
    source: new VectorSource({
        format: geoJSONMapProj,
        projection: proj,
        url: './static/PrivateRoads.geojson'
    }),
    updateWhileAnimating: true,
    zIndex:999
});


export const  TurnArounds = new VectorLayer({
    title: 'Turn Arounds',
    style:turnAroundStyle,
    source: new VectorSource({
        format: geoJSONMapProj,

        projection: proj,
        url: './static/Turnarounds.geojson'
    }),
    updateWhileAnimating: true,
    zIndex:999
});

TurnArounds.setProperties({'legendImageSrc': getLegendImage(TurnArounds)});

PrivateRoads.setProperties({'legendImageSrc': getLegendImage(PrivateRoads)});

WaterPoints.setProperties({'legendImageSrc': getLegendImage(WaterPoints)});


CWFHLayerGroup.getLayers().push(otherSoftwood);
CWFHLayerGroup.getLayers().push(WaterPoints);
CWFHLayerGroup.getLayers().push(TurnArounds);

const hubBoundary = new VectorLayer({
    title: 'Boundary',
    // legendImageSrc: getLegendImage(hubBoundary),
    style: mapStyles.hubBoundaryStyle,
    source: new VectorSource({
        format: geoJSONMapProj,
        projection: proj,
        url: './static/CWFH_Boundary.geojson'
    }),
    updateWhileAnimating: true,
    zIndex:999
});

hubBoundary.setProperties({'legendImageSrc': getLegendImage(hubBoundary)});

CWFHLayerGroup.getLayers().push(hubBoundary);

CWFHLayerGroup.getLayers().push(customers);


export const softwoodGroup = new LayerGroup({
    title: 'Softwood',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    visible: true
});

const softwoodByOwnerGroup = new LayerGroup({
    title: 'By Owner',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: true
});

export const maiGroup = new LayerGroup({
    title: 'Mai - (m3/ha/yr)',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    visible: false
});

const maiByClassGroup = new LayerGroup({
    title: 'By Class',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: true
});

const softwoodByRotationGroup = new LayerGroup({
    title: 'By Rotation',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: false
});

const softwoodByAgeGroup = new LayerGroup({
    title: 'By Age',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: false
});

const softwoodByAgeRotationGroup = new LayerGroup({
    title: 'By Age  & Rotation',
    layers: [],
    'fold': 'close',
    'group_visibility' : true,
    'toggle_all' : true,
    visible: false
});



maiGroup.getLayers().push(maiByClassGroup);

softwoodGroup.getLayers().push(softwoodByAgeRotationGroup);
softwoodGroup.getLayers().push(softwoodByAgeGroup);
softwoodGroup.getLayers().push(softwoodByRotationGroup);
softwoodGroup.getLayers().push(softwoodByOwnerGroup);

softwoodByOwnerGroup.on('change:visible',softwoodChangeVisibleGroup);
softwoodByRotationGroup.on('change:visible',softwoodChangeVisibleGroup);
softwoodByAgeGroup.on('change:visible',softwoodChangeVisibleGroup);
softwoodByAgeRotationGroup.on('change:visible',softwoodChangeVisibleGroup);



const maiSource = new VectorTileSource({
    format: new MVT(),
    url: systemBase+"/vt/mai_data/{z}/{x}/{y}.pbf",
    minZoom: 0,
    maxZoom: 13
});



maiSource.on('change:visible',displayLegend);

function displayLegend(e)
{
    if(e.oldValue == false)
    {
        document.getElementById("legend").style.display = "flex";
    }
    else
    {
        document.getElementById("legend").style.display = "none";
    }

   
}

CWFHLayerGroup.getLayers().push(haulageGroup);

const softwoodVTSource = new VectorTileSource({
    format: new MVT(),
    url: systemBase+"/vt/softwood_simple/{z}/{x}/{y}.pbf",
    minZoom: 0,
    maxZoom: 15
});



//Reverese order as added from then bottom up, and in legend as top down
const softwoodSources = ['Private', 'PPP', 'Hume','FCNSW','FCNSW JV'];

softwoodSources.forEach(s=>{
    opacityMap.set(s,1);
    setLayerOpacityMap(opacityMap);

    let svg = mapStyles.plantationStyleByOwnerLegend(s);

    let layer = new VectorTileLayer({
        title: s,
        enableOpacitySliders: true,
        onOpacityChange: fillLayerOpacityChange,
        getOpacityOveride: getFillLayerOpacity,
        source: softwoodVTSource,
        style:  (feature, resolution)=>{ return mapStyles.plantationStyleByOwner(feature, resolution, s) },
        visible: true,
        legendImageSrc: svg,
    });

    softwoodByOwnerGroup.getLayers().push(layer);
    
});

const MaiClasses = [20,17,15,13,11]; 

MaiClasses.forEach( maiClass => {
    opacityMap.set(maiClass,1);
    setLayerOpacityMap(opacityMap);

    let svg = mapStyles.MaiStylebByClassLegend(maiClass);

    let layer = new VectorTileLayer({
        title: maiClass,
        enableOpacitySliders: true,
        onOpacityChange: fillLayerOpacityChange,
        getOpacityOveride: getFillLayerOpacity,
        source: maiSource,
        style:  (feature, resolution)=>{ return mapStyles.MaiStylebByClass(feature, resolution, maiClass) },
        visible: true,
        legendImageSrc: svg,
    });

    maiByClassGroup.getLayers().push(layer);
    
});


const rotations = [4,3,2,1,0];

rotations.forEach(rotation=>{

    let mapkey = rotation + "R";

    opacityMap.set(mapkey,1);
    setLayerOpacityMap(opacityMap);
    

    let svg = mapStyles.plantationStyleByRotationLegend(rotation+"R");

    let layer = new VectorTileLayer({
        title: ''+rotation +"R",
        enableOpacitySliders: true,
        onOpacityChange: fillLayerOpacityChange,
        getOpacityOveride: getFillLayerOpacity,
        source: softwoodVTSource,
        style:  (feature, resolution)=>{ return mapStyles.plantationStyleByRotation(feature, resolution,mapkey) },
        visible: true,
        legendImageSrc: svg,
    });

    softwoodByRotationGroup.getLayers().push(layer);
});

const age = ["Clearfell in Progress","1990 & older","1991-2001","2001-2010","2011-2021","Planting in Progress","Unplanted","Regrowth"];

age.forEach(age=>{

    let svg = mapStyles.plantationStyleByAgeLegend(age);
    let mapkey = age;

    opacityMap.set(mapkey,1);
    setLayerOpacityMap(opacityMap);

    let layer = new VectorTileLayer({
        title: age,
        enableOpacitySliders: true,
        onOpacityChange: fillLayerOpacityChange,
        getOpacityOveride: getFillLayerOpacity,
        opacityLabel: "Opacity",
        source: softwoodVTSource,
        style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAge(feature, resolution, age) },
        visible: true,
        legendImageSrc: svg,
    });

    softwoodByAgeGroup.getLayers().push(layer);
});


const ageRotation = ["Other","1990 & older","1991-2001","2001-2010","2011-2021"];

ageRotation.forEach(ageRotation => {

    rotations.forEach(rotation => {
 
        if(ageRotation == "2011-2021")
        {
            if(rotation > 0 && rotation < 4)
            {
                let mapkey = ageRotation +','+rotation + 'R';

                opacityMap.set(mapkey,1);
                setLayerOpacityMap(opacityMap);
                
                 var layer = new VectorTileLayer({
                    title: ageRotation +','+rotation + 'R',
                    enableOpacitySliders: true,
                    onOpacityChange: fillLayerOpacityChange,
                    getOpacityOveride: getFillLayerOpacity,
                    opacityLabel: "Opacity",
                    source: softwoodVTSource,
                    style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, rotation+"R",ageRotation) },
                    visible: true
                });

                layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation:rotation+"R", agegroup:ageRotation }))});
            }
        }
        else if (ageRotation == "2001-2010")
        {
            let mapkey = ageRotation +','+rotation + 'R';

            opacityMap.set(mapkey,1);
            setLayerOpacityMap(opacityMap);

            var layer = new VectorTileLayer({
                title: ageRotation +','+rotation + 'R',
                enableOpacitySliders: true,
                onOpacityChange: fillLayerOpacityChange,
                getOpacityOveride: getFillLayerOpacity,
                opacityLabel: "Opacity",
                source: softwoodVTSource,
                style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, rotation+"R",ageRotation) },
                visible: true
            });

            layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation:rotation+"R", agegroup:ageRotation }))});
        }
        else if (ageRotation == "1991-2001")
        {
            if(rotation < 3)
            {
                let mapkey = ageRotation +','+rotation + 'R';

                opacityMap.set(mapkey,1);
                setLayerOpacityMap(opacityMap);

               var  layer = new VectorTileLayer({
                    title: ageRotation +','+rotation + 'R',
                    enableOpacitySliders: true,
                    onOpacityChange: fillLayerOpacityChange,
                    getOpacityOveride: getFillLayerOpacity,
                    opacityLabel: "Opacity",
                    source: softwoodVTSource,
                    style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, rotation+"R",ageRotation) },
                    visible: true
                });

                layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation:rotation+"R", agegroup:ageRotation }))});
            }

        }

        else if(ageRotation == "1990 & older")
        {
            if(rotation > 0 && rotation < 4)
            {
                let mapkey = ageRotation +','+rotation + 'R';

                opacityMap.set(mapkey,1);
                setLayerOpacityMap(opacityMap);

               var layer = new VectorTileLayer({
                    title: ageRotation +','+rotation + 'R',
                    enableOpacitySliders: true,
                    onOpacityChange: fillLayerOpacityChange,
                    getOpacityOveride: getFillLayerOpacity,
                    opacityLabel: "Opacity",
                    source: softwoodVTSource,
                    style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, rotation+"R",ageRotation) },
                    visible: true
                });
                layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation:rotation+"R", agegroup:ageRotation }))});
            }
        }
        

        if(typeof layer != 'undefined' )
        softwoodByAgeRotationGroup.getLayers().push(layer);
    })

 if (ageRotation == "Other")
        {
            let mapkey = "Other";

            opacityMap.set(mapkey,100);
            setLayerOpacityMap(opacityMap);

             var layer = new VectorTileLayer({
                title: "Other",
                enableOpacitySliders: true,
                onOpacityChange: fillLayerOpacityChange,
                getOpacityOveride: getFillLayerOpacity,
                opacityLabel: "Opacity",
                source: softwoodVTSource,
                style:  (feature, resolution)=>{ return mapStyles.plantationStyleByAgeRotation(feature, resolution, "Other", "Other") },
                visible: true
            });

            layer.setProperties({'legendImageSrc': getLegendImage(layer, 'Polygon', new Feature({rotation: "Other", agegroup: "Other" }))});
        }
        if(typeof layer != 'undefined' )
        softwoodByAgeRotationGroup.getLayers().push(layer);

});

const roadsGroup = new LayerGroup({
    title: 'Roads',
    layers: [],
    'fold': 'open',
    'group_visibility' : true,
    visible: false
});

const rodsVTSource = new VectorTileSource({
    format: new MVT(),
    url: systemBase+"/vt/roads/{z}/{x}/{y}.pbf",
    minZoom: 0,
    maxZoom: 15
});


    
const sealedRoads = new VectorTileLayer({
    title: 'Sealed',
    source: rodsVTSource,
    style:  (feature, resolution)=>{ return mapStyles.roadStyle(feature, resolution, 1) },
    visible: true
});

const gravelRoads = new VectorTileLayer({
    title: 'Gravel',
    source: rodsVTSource,
    style:  (feature, resolution)=>{ return mapStyles.roadStyle(feature, resolution, 2) },
    visible: true
});

const naturalRoads = new VectorTileLayer({
    title: 'Natural Surface',
    source: rodsVTSource,
    style:  (feature, resolution)=>{ return mapStyles.roadStyle(feature, resolution, 3) },
    visible: false
});


sealedRoads.setProperties({'legendImageSrc': getLegendImage(sealedRoads, 'LineString', new Feature({surface:1}))});
gravelRoads.setProperties({'legendImageSrc': getLegendImage(gravelRoads, 'LineString', new Feature({surface:2}))});
naturalRoads.setProperties({'legendImageSrc': getLegendImage(naturalRoads, 'LineString', new Feature({surface:3}))});

roadsGroup.getLayers().push(PrivateRoads);
roadsGroup.getLayers().push(naturalRoads);
roadsGroup.getLayers().push(gravelRoads);
roadsGroup.getLayers().push(sealedRoads);


CWFHLayerGroup.getLayers().push(maiGroup);
CWFHLayerGroup.getLayers().push(roadsGroup);
CWFHLayerGroup.getLayers().push(softwoodGroup);




//Mutx for softwood group layers
function softwoodChangeVisibleGroup(event){
    let changedGroup = event.target

    if(!changedGroup.getVisible()) return;
    
    softwoodGroup.getLayers().getArray().forEach(l=>{
        if(l != changedGroup){
            l.setVisible(false);
        }
    });
}


function haulageChangeVisibleGroup(event){
    let changedGroup = event.target

    if(!changedGroup.getVisible()) return;
    
    haulageGroup.getLayers().getArray().forEach(l=>{
        if(l != changedGroup){
            l.setVisible(false);
        }
        else
        {
            generateHaulageLegendPopup(l)
        }
    });
}

function generateHaulageLegendPopup(layerGroup)
{
    let legendPopup = document.getElementById("legend");
    legendPopup.innerHTML = '';

    layerGroup.getLayers().forEach((layer) => 
    {

        var title = layer.values_.title;
        let cleanedTitle = title.replace(/ /g, "").replace(/,/g,"").split("-");
        var lastValue = cleanedTitle[1];
        let splitTitle = title.replace('-','').replace(" ", "").split(" ");

        let container = document.createElement("div");
        container.className = "flex-container center";
        
        let upperText = document.createElement("p");
        
        upperText.textContent = splitTitle[0];

        let lowerText = document.createElement("p");
        
        lowerText.textContent = splitTitle[1];
        
        let img = document.createElement("img");

        if(layerGroup.values_.title.includes("2021"))
        img.src = getLegendImage(layer, 'LineString', new Feature({TOT_VOL_20:parseInt(lastValue)}));
        if(layerGroup.values_.title.includes("2031"))
        img.src = getLegendImage(layer, 'LineString', new Feature({TOT_VOL_21:parseInt(lastValue)}));
        if(layerGroup.values_.title.includes("2041"))
        img.src = getLegendImage(layer, 'LineString', new Feature({TOT_VOL_22:parseInt(lastValue)}));

        container.appendChild(upperText);
        container.appendChild(img);
        container.appendChild(lowerText);
        legendPopup.appendChild(container);
    })
}
